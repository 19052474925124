export default [
  // {
  //   title: 'Entities',
  //   icon: 'BriefcaseIcon',
  //   route: 'entities',
  //   action: 'manage',
  //   resource: 'licences',
  // },
  {
    title: 'Business Licences',
    route: 'licenses',
    action: 'manage',
    resource: 'licences',
  },
  {
    title: 'Broadcasting Licenses',
    icon: 'CameraIcon',
    route: 'licenses-broadcasting',
    action: 'manage',
    resource: 'licences'
  },
  {
    title: 'PSV Licences',
    icon: 'ChevronsRightIcon',
    route: 'licenses-psv',
    action: 'manage',
    resource: 'licences',
  },
  {
    title: 'Invoices',
    icon: 'FileTextIcon',
    route: 'invoices',
    action: 'manage',
    resource: 'licences', 
  },
]