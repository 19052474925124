<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.first_name }} {{ userData.last_name }}
        </p>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
      >
        <feather-icon
          v-if="!userData.first_name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('user')),
      avatarText,
    }
  },
  methods: {
    logout() {
      this.$http.post('/auth/logout')
        .then(() => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
    
          // Remove userData from localStorage
          localStorage.removeItem('user')
          localStorage.removeItem('permissions')
    
          // Reset ability
          this.$ability.update(initialAbility)
    
          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        })
        .catch(() => {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `An error occurred`,
                icon: 'CoffeeIcon',
                variant: 'error',
                text: `There was error while logging out. Please try again.`,
              },
            })
        })
    },
  },
}
</script>
